.art-cell{
    display: flex;
    flex-direction: column;
}

.trackinfo{
    text-align: left;
    /* width: 150px; */
}

.album-art{
    margin-top: 5px;
    max-height: 60px;
    max-width: 60px;
    margin-bottom: 5px;
}

.trackname{
    font-size: 1em;
    padding-left: 10px;
}

.artistname{
    font-size: 0.7em;
    padding-left: 10px;
    color: #b6b6cd
}

.music-data{
    font-size: 0.9em;
}

@media screen and (max-width: 720px) {
    .music-data{
        font-size: 0.8em;
    }

    .trackname{
        font-size: 0.9em;
    }

    .album-art{
        max-height: 45px;
        max-width: 45px;
    }
}