canvas {
    background: linear-gradient(rgb(18,15,23), rgb(23,20,28));
    display: block;
}
  
#http-error-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.error-code{
    font-size: 4rem;
    width: fit-content;
}

.error-body{
    font-size: 1.25rem;
    text-align: center;
}