/*subheadings*/
.subheading{
    margin: 1.25rem;
    margin-bottom: 0.75rem;
    font-size: 1.5em;
    text-align: center;
    padding-top: 1.25rem;
}

/*my button*/
.mybtn{
    height: 45.6px;
    width: 230.4px;
    border-radius: 30px;
    border: none;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    background-image: linear-gradient(175deg, rgb(255, 68, 99) 12%, rgb(255, 145, 0));
    color: #FFFFFF;
    font-size: 1em;
    overflow: hidden;
    white-space: nowrap;
}

.mybtn:hover{
    background-image: linear-gradient(175deg, rgb(255, 90, 115) 12%, rgb(255, 165, 0));
    cursor: pointer;
    color: white;
}
