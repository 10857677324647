.centerContent{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.instructionText  {
    width: min(100%, 800px);
}

.instruction-icon {
    color: white;
    height: 28px;
    width: 28px;
    margin-right: 10px;
    transform: translateY(2px)
}

.banner{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bText{
    margin: 20px 15px;
    line-height: 1.5;
    font-size: 1.05rem;
}

.FAQ-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
}

.FAQ-icon{
    margin-top: 35px;
}

.FAQ-span{
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.ant-card-body{
    padding-top: 0px;
}

/* .headerIcon{
    margin-top: 16px;
    margin-right: 10px;
} */

@media screen and (max-width: 440px) {
    .myTitle{
        font-size: 1.8rem;
    }
}