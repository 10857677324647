.navMenuText#override-antd:hover{
    background-image: var(--myGradient);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    transition: all 0.1s linear;
}

.navMenuText#override-antd::after {
    content: "";
    display: block;
    position: absolute;
    left: 15%;
    width: 0;
    height: 2px;
    transform: translateY(-15px);
    background-image: var(--myGradient);
    opacity: 1;
    transition: width 0.2s linear;
    border-radius: 2px;
}
  
.navMenuText#override-antd:hover::after {
    width: 70%;
}

#profile-btn{
    margin-left: auto;
}

#Logo-img{
    top: 50%;
    height: 50px;
    width: 50px;
    transform: translateY(20%);
}

#Logo-img{
    transition: all 0.5s ease-in-out;
}
#Logo-img:hover{
    transform: rotate(35deg) translateY(7px) translateX(7px);
}

.ant-menu-submenu-title{
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
}

@media screen and (min-width: 720px) {
    #profile-btn{
        margin-left: auto;
        margin-right: 30px
    }

    #Logo{
        margin-left: 30px;
    }
}

