* {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.entry{
    padding-bottom: 60px;
}

.summaryWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 25px;
}

.appSummary{
    padding: 50px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    text-align: center;
    justify-content: center;
}

.appSummaryText{
    padding: 5px;
}

.heroBtnContainer{
    align-self: center;
    display: flex;
    flex-direction: row;
    gap: 20px
}

#carouselTile{
    display: none;
}

.carouselTileContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 720px) {
    .appSummary{
        align-items: flex-start;
        text-align: left;
        margin-top: 50px;
    }

    .heroSection{
        min-height: calc(100vh - 120px);
    }

    .heroBtnContainer{
        margin-top: 30px;
        align-self: flex-end;
    }

    .summaryWrapper{
        padding-top: 25px;
    }
}


@media screen and (min-width: 1040px) {
    .carouselTileContainer{
        flex-direction: row;
    }

    #carouselTile{
        display: flex;
        margin-bottom: 0px;
        background-image: var(--myGradient);
        border: none;
        border-radius: 20px;
        color: white;
        font-size: 1.4rem;
        width: 180px;
        height: 240px;
        display: flex;
        align-items: center;
        text-align: center;
        box-shadow: 4px 4px 12px -2px rgba(255,180,74,0.89);
    }

}

@media screen and (max-width: 500px) {
    .heroBtnContainer{
        flex-direction: column;
    }
}