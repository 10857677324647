#sw-container{
    align-self: center;
}

.soundwave-container{
    display: grid;
}

.sound-wave{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0px 50px 0px;
}

.bar{
    animation-name: wave-lg;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    background: linear-gradient(rgb(255, 68, 99) 7%, rgb(255, 145, 0));
    margin: 0 1.5px;
    height: 10px;
    width: 3px;
    border-radius: 2px;
}

body .bar:nth-child(-n + 7), body .bar:nth-last-child(-n + 7) {
    animation-name: wave-md;
}
body .bar:nth-child(-n + 3), body .bar:nth-last-child(-n + 3) {
    animation-name: wave-sm;
}

@keyframes wave-sm {
    0% {
        opacity: 0.35;
        height: 10px;
   }
    100% {
        opacity: 1;
        height: 25px;
   }
}

@keyframes wave-md {
    0% {
        opacity: 0.35;
        height: 15px;
   }
    100% {
        opacity: 1;
        height: 50px;
   }
}
@keyframes wave-lg {
    0% {
        opacity: 0.35;
        height: 15px;
   }
    100% {
        opacity: 1;
        height: 70px;
   }
}